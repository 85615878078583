import { Directive, HostListener } from "@angular/core";

@Directive({
	selector: "[StopClickPropagation]",
	standalone: true,
})
export class StopClickPropagationDirective {
	@HostListener("click", ["$event"])
	public stopEventPropagation(event: MouseEvent): void {
		event.stopPropagation();
	}
}
